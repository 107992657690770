<template>
  <section id="contact-form">
    <h3>
      お問い合わせ
      <hr />
    </h3>
    <div class="announce margin-bottom-40">
      <h4>メールでのお問い合わせ</h4>
    </div>
    <div class="wrap">
      <div id="input" class="contact-form padding-y-100">
        <div v-if="displayType === '1'">
          <div class="form-row">
            <div class="form-text">
              <div class="hissu">必須</div>
              <p class="h-white">名前</p>
            </div>
            <input
              type="text"
              v-model="formData.name"
              class="form-content"
              placeholder="山田 太郎"
            />
            <p class="error-message" v-if="validation">
              {{ messageData.name }}
            </p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <div class="hissu">必須</div>
              <p class="h-white">メールアドレス</p>
            </div>
            <input
              type="email"
              v-model="formData.email"
              class="form-content"
              placeholder="sample@triage-ia.com"
            />
            <p class="error-message" v-if="validation">
              {{ messageData.email }}
            </p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <div class="hissu">必須</div>
              <p class="h-white">電話番号</p>
            </div>
            <input
              type="text"
              v-model="formData.tel"
              class="form-content"
              placeholder="0123456789（ハイフンなし）"
            />
            <p class="error-message" v-if="validation">{{ messageData.tel }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <div class="hissu">必須</div>
              <p class="h-white">内容</p>
            </div>
            <textarea
              name="text"
              id="text"
              v-model="formData.text"
              class="form-content"
              placeholder="お問い合わせ内容を入力してください"
            ></textarea>
            <p class="error-message" v-if="validation">
              {{ messageData.text }}
            </p>
          </div>
          <p class="privacy-policy">
            <router-link to="/privacy-policy">
              プライバシーポリシー
            </router-link>
            が適用されます。
          </p>
          <button @click="checkValidate()">確認</button>
        </div>
        <div v-if="displayType === '2'">
          <h4>入力内容確認</h4>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">名前</p>
            </div>
            <p class="check-content">{{ formData.name }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">メールアドレス</p>
            </div>
            <p class="check-content">{{ formData.email }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">電話番号</p>
            </div>
            <p class="check-content">{{ formData.tel }}</p>
          </div>
          <div class="form-row">
            <div class="form-text">
              <p class="h-white">内容</p>
            </div>
            <textarea
              class="check-content"
              v-model="formData.text"
              readonly
            ></textarea>
          </div>
          <div class="form-button">
            <button @click="back()">戻る</button>
            <button @click="send()">送信</button>
          </div>
        </div>
      </div>
    </div>
    <div class="announce margin-bottom-40">
      <h4>お電話でのお問い合わせ</h4>
    </div>
    <div class="wrap">
      <a href="tel:0585454283" class="phone-number">0585-45-4283</a>
      <div class="business-hours margin-bottom-40">
        【営業時間：平日 9時〜17時】
      </div>
      <div class="other-button">
        <router-link to="/solicitation-policy" v-scroll-to="'#app'">
          勧誘方針はこちら
        </router-link>
        <router-link to="/privacy-policy" v-scroll-to="'#app'">
          プライバシーポリシー
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ContactForm",
  data() {
    return {
      displayType: "1",
      validation: false,
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      telPattern: /^0\d{9,10}$/,
      formData: {
        name: "",
        email: "",
        tel: "",
        text: "",
      },
      messageData: {
        name: "",
        email: "",
        tel: "",
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    checkValidate() {
      // 各入力項目の入力チェック
      this.validation = this.nameValid(this.formData.name);
      this.validation = this.emailValid(this.formData.email);
      this.validation = this.telValid(this.formData.tel);
      this.validation = this.textValid(this.formData.text);
      // メッセージの状態チェック
      this.messageCheck();
      // バリデーションがfalseになっているか確認
      if (this.validation !== true) {
        this.displayType = "2";
      } else {
        // no action
      }
      this.$scrollTo("#input", 500, { easing: "ease" });
    },
    nameValid(name) {
      if (name !== "") {
        // ok
      } else {
        this.messageData.name = "名前を入力してください";
        return true;
      }

      // 初期化
      this.messageData.name = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    emailValid(email) {
      if (email !== "") {
        if (this.emailPattern.test(email)) {
          // ok
        } else {
          this.messageData.email =
            "メールアドレスは正しい形式で入力してください。";
          return true;
        }
      } else {
        this.messageData.email = "メールアドレスを入力してください";
        return true;
      }

      // 初期化
      this.messageData.email = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    telValid(tel) {
      if (tel !== "") {
        if (this.telPattern.test(tel)) {
          // ok
        } else {
          this.messageData.tel = "正しい電話番号を入力してください";
          return true;
        }
      } else {
        this.messageData.tel = "電話番号を入力してください";
        return true;
      }

      // 初期化
      this.messageData.tel = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    textValid(text) {
      if (text !== "") {
        // ok
      } else {
        this.messageData.text = "お問い合わせ内容を入力してください";
        return true;
      }

      // 初期化
      this.messageData.text = "";
      return this.validation !== false
        ? this.validation
        : (this.validation = false);
    },
    messageCheck() {
      // メッセージが残っていなかったらバリデーションをfalseにする
      for (const key in this.messageData) {
        if (this.messageData[key] === "") {
          // ok
        } else {
          this.validation = true;
          break;
        }
        this.validation = false;
      }
    },
    //確認画面
    back() {
      this.displayType = "1";
      this.$scrollTo("#input", 500, { easing: "ease" });
    },
    // メール送信
    async send() {
      let params = new URLSearchParams();
      // パラメーター追加
      params.append("name", this.formData.name);
      params.append("email", this.formData.email);
      params.append("tel", this.formData.tel);
      params.append("text", this.formData.text);

      axios
        .post(this.rootUrl.rootUrl + "/api/send.php", params)
        .then((response) => {
          if (response.data.result) {
            location.href = "/";
          } else {
            // no action
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  h4 {
    text-align: center;
    color: var(--white);
  }
  .contact-form {
    background-color: var(--gray);
    .form-row {
      width: 90%;
      margin: 0 auto 30px auto;
      @media screen and (max-width: 1150px) {
        margin: 0 auto 20px auto;
      }
      .form-text {
        display: flex;
        .hissu {
          font-size: 20px;
          text-align: center;
          color: var(--white);
          background-color: var(--main);
          width: 10%;
          @media screen and (max-width: 767px) {
            width: 30%;
          }
        }
        p {
          font-size: 18px;
          padding-left: 15px;
          width: 90%;
          color: var(--black);
          @media screen and (max-width: 767px) {
            width: 70%;
          }
        }
      }
      .form-content {
        font-size: 18px;
        width: 100%;
        padding: 10px 10px;
        margin-top: 10px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          margin-top: 5px;
        }
      }
      textarea {
        height: 150px;
        resize: none;
      }
      .error-message {
        font-size: 18px;
        color: var(--main);
        line-height: 1.5rem;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .check-content {
        font-size: 18px;
        color: var(--black);
        background-color: var(--white);
        width: 100%;
        padding: 10px 10px;
        margin-top: 10px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
    .privacy-policy {
      font-size: 20px;
      text-align: center;
      color: var(--black);
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
      a {
        color: var(--black);
      }
      &:hover {
        color: var(--main);
        a {
          color: var(--main);
        }
      }
    }
    button {
      display: block;
      font-size: 18px;
      padding: 20px 0;
      width: 20%;
      cursor: pointer;
      margin: 0 auto;
      color: var(--black);
      background-color: var(--gray);
      border: 1px solid var(--black);
      &:hover {
        color: var(--black);
        background-color: var(--white);
        border: 1px solid var(--white);
      }
      @media screen and (max-width: 767px) {
        padding: 10px 0;
        width: 40%;
      }
    }
    h4 {
      color: var(--black);
    }
    .form-button {
      display: flex;
      justify-content: space-between;
    }
  }
  .announce {
    margin-top: 100px;
    margin-bottom: 50px;
    @media screen and (max-width: 1150px) {
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  .phone-number {
    display: block;
    font-size: 75px;
    line-height: 75px;
    color: var(--main);
    text-align: center;
    text-decoration: none;
    @media screen and (min-width: 768px) {
      // 現話番号のクリックを無効化する
      pointer-events: none;
      cursor: default;
    }
    @media screen and (max-width: 767px) {
      font-size: 45px;
      line-height: 45px;
      text-wrap: nowrap;
    }
  }
  .business-hours {
    font-size: 25px;
    color: var(--black);
    margin-top: 10px;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .other-button {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
    a {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      width: 45%;
      padding: 20px 0;
      color: var(--black);
      border: 1px solid var(--black);
      &:hover {
        background-color: var(--sub);
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 10px 0;
        margin: 0 auto 10px auto;
      }
    }
  }
}
</style>
