<template>
  <section class="detail-page">
    <h1>
      お客さまに関する情報の取り扱いについて
      <br class="sp-only tablet-only" />
      プライバシーポリシー
      <hr />
    </h1>
    <div class="wrap">
      <p>
        当社は、個人情報保護の重要性に鑑み、また保険業に対するお客さまの信頼をより向上させるため、個人情報の保護に関する法律（個人情報保護法）、行政手続における特定の個人を識別するための番号の利用等に関する法律（番号法）、その他の関係法令、関係官庁からのガイドライン、特定個人情報の適正な取扱いに関するガイドラインなどを遵守して、個人情報を厳正・適切に取り扱うとともに、安全管理について適切な措置を講じます。
      </p>
      <p>
        当社は、個人情報の取扱いが適正に行われるように、従業者への教育・指導を徹底し、適正な取扱いが行われるよう取り組んでまいります。また、個人情報の取扱いに関する苦情・ご相談に迅速に対応し、当社の個人情報の取扱いおよび安全管理に係る適切な措置については、適宜見直し、改善いたします。
      </p>
      <!-- １．個人情報の取得 -->
      <h2>１．個人情報の取得</h2>
      <p>
        当社は、業務上必要な範囲内で、かつ、適法で公正な手段により個人情報を取得します。
      </p>
      <p>（下記６．の個人番号および特定個人情報を除きます。）</p>
      <!-- ２．個人情報の利用目的 -->
      <h2>２．個人情報の利用目的</h2>
      <p>
        当社は、保険会社から保険募集業務の委託をうけて、取得した個人情報（個人番号および特定個人情報については、下記６．をご覧ください。）を当該業務の遂行に必要な範囲内で利用します。当社は前ページ記載の保険会社と取引があり、取得した個人情報を取引のある保険会社の商品・サービスをご提案するために利用させていただくことがあります。当社における具体的な個人情報の利用目的は次のとおりであり、それら以外の他の目的に利用することはありません。
      </p>
      <p class="rule-title">
        ●当社が取り扱う損害保険、生命保険およびこれらに付帯・関連するサービスの提供
      </p>
      <p>
        上記の利用目的の変更は、相当の関連性を有すると合理的に認められている範囲にて行い、変更する場合には、その内容をご本人に対し、原則として書面などにより通知し、または当社のホームページなどにより公表します。当社に対し保険業務の委託を行う保険会社の利用目的は、保険会社のホームページに記載してあります。
      </p>
      <!-- ３．個人データの安全管理措置 -->
      <h2>３．個人データの安全管理措置</h2>
      <p>
        当社は、取り扱う個人データ（下記６．の個人番号および特定個人情報を含みます。）の漏えい、滅失または毀損の防止、その他個人データの安全管理のため、安全管理に関する取扱規程などの整備および実施体制の整備など、十分なセキュリティ対策を講じるとともに、利用目的の達成に必要とされる正確性・最新性を確保するための適切な措置を講じ、万が一、問題等が発生した場合は、速やかに適当な是正対策を行います。
      </p>
      <!-- ４．個人データの第三者への提供 -->
      <h2>４．個人データの第三者への提供</h2>
      <p>
        当社は、次の場合を除き、あらかじめご本人の同意なく第三者に個人データ（個人番号および特定個人情報については、下記６．をご覧ください。）を提供しません。
      </p>
      <p>①法令に基づく場合</p>
      <p>
        ②人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
      </p>
      <p>
        ③公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。
      </p>
      <p>
        ④国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
        <br />
        また、個人データを第三者に提供したとき、あるいは第三者から提供をうけたとき、提供・取得経緯等の確認を行うとともに、提供先・提供者の氏名等、法令で定める事項を記録し、保管します。
      </p>
      <!-- ５．センシティブ情報の取扱い -->
      <h2>５．センシティブ情報の取扱い</h2>
      <p>
        当社は、要配慮個人情報（人種、信条、社会的身分、病歴、前科・前歴、犯罪被害情報などをいいます）ならびに労働組合への加盟、門地および本籍地、保健医療および性生活に関する個人情報（センシティブ情報）については、次の場合を除き、原則として取得、利用または第三者提供を行いません。
      </p>
      <p>①法令等に基づく場合</p>
      <p>②人の生命、身体又は財産の保護のために必要がある場合</p>
      <p>③公衆衛生の向上又は児童の健全な育成の推進のため特に必要がある場合</p>
      <p>
        ④国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
      </p>
      <p>
        ⑤保険料収納事務等の遂行上必要な範囲において、政治・宗教等の団体若しくは労働組合への所属若しくは加盟に関する従業員等のセンシティブ情報を取得、利用又は第三者提供する場合
      </p>
      <p>
        ⑥相続手続を伴う保険金支払事務等の遂行に必要な限りにおいて、センシティブ情報を取得、利用又は第三者提供する場合
      </p>
      <p>
        ⑦保険業の適切な業務運営を確保する必要性から、本人の同意に基づき業務遂行上必要な範囲でセンシティブ情報を取得、利用又は第三者提供する場合
      </p>
      <!-- ６．個人番号および特定個人情報の取扱い -->
      <h2>６．個人番号および特定個人情報の取扱い</h2>
      <p>
        当社は、個人番号および特定個人情報について、法令で限定的に明記された目的以外のために取得・利用しません。番号法で限定的に明示された場合を除き、個人番号および特定個人情報を第三者に提供しません。
      </p>
      <!-- ７．個人情報保護法に基づき保有個人データの開示、訂正、利用停止など -->
      <h2>
        ７．個人情報保護法に基づき保有個人データの開示、訂正、利用停止など
      </h2>
      <p>
        個人情報保護法に基づく保有個人データ（上記６．の個人番号および特定個人情報を含みます。）に関する開示、訂正または利用停止などに関するご請求については、ご請求者が本人であることをご確認させていただいたうえで、手続きを行います。
        保険会社や他社の保有個人データに関しては当該会社に対してお取次ぎいたします。
      </p>
      <!-- ８．お問い合わせ先 -->
      <h2>８．お問い合わせ先</h2>
      <p>
        ご連絡先は前ページ記載のお問い合わせ窓口となります。また保険事故に関する照会については、前ページ記載のお問い合わせ窓口のほか、保険証券記載の保険会社の事故相談窓口にもお問い合わせいただくことができます。なお、ご照会者がご本人であることをご確認させていただいたうえで、ご対応させていただきますので、あらかじめご了承願います。
      </p>
      <p>
        ※当社からのＥメール、ダイレクトメール等による新商品・サービスのご案内について、ご希望されない場合は、前ページ記載のお問い合わせ先までお申し出ください。
      </p>
      <p class="fin">以上</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicyComponent",
};
</script>

<style lang="scss">
.detail-page {
  h1 {
    line-height: 2.5rem;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      font-weight: bold;
      line-height: 2rem;
    }
  }
  .wrap {
    h2 {
      font-size: 30px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        font-weight: bold;
      }
    }
    p {
      line-height: 1.5rem;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
        margin-bottom: 7px;
      }
    }
    .rule-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
</style>
