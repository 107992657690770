<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline':
          (scrollY >= 1000 && path === '/') || (scrollY >= 0 && path !== '/'),
      }"
    >
      <div class="header-cover">
        <div class="header-left">
          <div class="to-top" @click="top">
            <img
              src="../../assets/triage_top_logo0003.webp"
              alt="有限会社トリアージ"
              class="header-img"
            />
          </div>
        </div>
        <div class="header-right">
          <router-link to="/" class="header-line pc-only" v-scroll-to="'#line'">
            <div class="latest-info">保険やお金に関する最新情報はこちら</div>
            <img
              src="../../assets/triage_top_LINE.webp"
              alt="友だち追加"
              class="header-line-button"
            />
          </router-link>
          <router-link
            to="/"
            v-scroll-to="'#contact-form'"
            class="pc-tablet-only"
          >
            <span>お問い合わせ</span>
          </router-link>
          <div class="hamburger">
            <UiDrawer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "../Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      //  documentheight:0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);//長さはかるコンソール
          }.bind(this),
          200
        );
      } else {
        // no action
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--main);
}
.st1 {
  fill: none;
}

.header {
  background-color: var(--white);
  width: 100vw;
  height: 80px;
  border-top: 2px solid var(--black);
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 1s;
  top: 0;
  left: 0;
  @media screen and (max-width: 767px) {
    height: 60px;
  }
  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 2%;
    width: 96%;
  }
  .header-left {
    display: flex;
    align-items: center;
    .to-top {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      img {
        &.header-img {
          width: 250px;
          display: block;
          margin: auto 0;
          @media screen and (max-width: 767px) {
            width: 200px;
          }
        }
      }
    }
  }

  .header-right {
    width: 670px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: var(--black);
      padding: 0 15px;
      border: 1px solid var(--black);
      span {
        line-height: 1rem;
      }
      &:hover {
        background-color: var(--sub);
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      margin-right: 1%;
      width: 200px;
    }
    @media screen and (max-width: 767px) {
      width: 30%;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .header-line {
      display: flex;
      border: 0;
      cursor: pointer;
      &:hover {
        color: var(--line);
        background-color: #ffffff00;
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        display: none;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
      .latest-info {
        font-size: 16px;
        padding: 0 5px;
        border-bottom: 2px solid var(--line);
      }
      .header-line-button {
        display: block;
        width: 140px;
        margin: auto 0;
      }
    }
  }
}
.over-baseline {
  transition: 2s;
  background-color: rgba(255, 255, 255, 0.2);
  .header-cover {
    border: none;
  }
}
</style>
