<template>
  <section class="detail-page">
    <h1>
      「金融サービスの提供に関する法律」
      <br class="sp-only tablet-only" />
      に基づく勧誘方針
      <hr />
    </h1>
    <div class="wrap">
      <h2>お客さまへのお知らせ</h2>
      <p class="rule-title">
        「金融サービスの提供に関する法律」に基づき、当代理店の金融商品の勧誘方針を次のとおり定めておりますので、ご案内いたします。
      </p>
      <p>
        ・保険法、保険業法、金融サービスの提供に関する法律、金融商品取引法、消費者契約法、個人情報の保護に関する法律およびその他各種法令等を遵守し、適正な商品販売に努めてまいります。
      </p>
      <p>
        ・お客さまに商品内容を十分ご理解いただけるよう、知識の修得、研さんに励むとともに、説明方法等について工夫し、わかりやすい説明に努めてまいります。
      </p>
      <p>
        ・お客さまの商品に関する知識、経験、財産の状況および購入の目的等を総合的に勘案し、お客さまに適切な商品をご選択いただけるよう、お客さまのご意向と実情に沿った説明に努めてまいります。
      </p>
      <p>
        ・金融市場等の動向がお客さまのリスクに成り得る外貨建保険、変額個人年金保険等の勧誘につきましては、お客さまご自身のご判断と責任でお取引いただけますように、「契約締結前交付書面（契約概要／注意喚起情報）」等の説明による、適切な情報提供に努めてまいります。
      </p>
      <p>
        ・市場の動向に大きく影響される投資性商品については、リスクの内容について、適切な説明に努めてまいります。
      </p>
      <p>
        ・商品の販売にあたっては、お客さまの立場に立って、時間帯、場所、方法等について十分配慮いたします。
      </p>
      <p>
        ・事前のご要望がある場合を除き、社会通念上不適当な時間帯の電話や訪問は自粛いたします。お客さまを著しく困惑させる行為やお客さまの意思に反する行為は行いません。
      </p>
      <p>
        ・お客さまに関する情報については、適正に取り扱うとともに厳正に管理いたします。
      </p>
      <p>
        ・お客さまのご意見、ご要望等を、商品ご提供の参考にさせていただくよう努めてまいります。
      </p>
      <p>
        ・万一保険事故が発生した場合には、保険金のご請求にあたり適切な助言を行うよう努めてまいります。
      </p>
      <p>
        ・保険金を不正に取得されることを防止する観点から、適正に保険金額を定める等、適切な商品の販売に努めてまいります。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "SolicitationPolicyComponent",
};
</script>

<style lang="scss"></style>
