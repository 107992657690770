<template>
  <section>
    <h1>
      有限会社トリアージについて
      <hr />
    </h1>
    <div class="wrap">
      <div class="content">
        <img
          src="../../assets/triage_top_0001.webp"
          alt="有限会社トリアージについて"
        />
        <p>
          有限会社トリアージは、揖斐郡池田町にある来店型保険代理店です。自動車・火災・傷害・生命保険と全分野対応でき、いつでもお気軽にご相談いただけます。地域に寄り添った、頼れる街の保険屋さんを目指しています。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-top: 200px;
  padding-bottom: 0;
  @media screen and (max-width: 1100px) {
    padding-top: 60px;
    padding-bottom: 0;
  }
  p {
    margin-top: 20px;
  }
}
</style>
