import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import SolicitationPolicy from "@/views/SolicitationPolicy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import store from "@/store";
import { setTitle, setDescription } from "@/mixins/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "有限会社トリアージ｜揖斐郡池田町保険代理店",
      description:
        "有限会社トリアージは、岐阜県揖斐郡池田町にある来店型保険の相談窓口です。保険代理店ですので、自動車保険・火災保険・傷害保険・生命保険と全分野対応できます。資産運用、NISA、iDeCoのご相談も承っていますのでお気軽にお立ち寄りください。",
    },
  },
  {
    path: "/solicitation-policy",
    name: "solicitation-policy",
    component: SolicitationPolicy,
    meta: {
      title: "勧誘方針｜有限会社トリアージ",
      description: "有限会社トリアージのプライバシーポリシーを掲載しています。",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "プライバシーポリシー｜有限会社トリアージ",
      description: "有限会社トリアージの勧誘方針を掲載しています。",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;
