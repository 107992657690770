<template>
  <div class="top">
    <video autoplay muted playsinline loop>
      <source src="video/bg.mp4" />
      <!-- <source src="../../assets/shutterstock_1070962876.mov" /> -->
    </video>
    <img
      src="../../assets/triage_top_logo0002.webp"
      alt="揖斐郡初の来店型保険ショップ 有限会社トリアージ SDGs 岐阜県推進ネットワーク会員に認定(PC)"
      class="pc-tablet-only"
    />
    <img
      src="../../assets/triage_top_logo0002sp.webp"
      alt="揖斐郡初の来店型保険ショップ 有限会社トリアージ SDGs 岐阜県推進ネットワーク会員に認定(SP)"
      class="sp-logo sp-only"
    />
    <div class="top-cover"></div>
    <img
      src="../../assets/triage_top_moji0001.webp"
      alt="保険を通してお客様のライフプランを提案します（PC）"
      class="top-message pc-only"
    />
    <img
      src="../../assets/triage_top_moji0001sp.webp"
      alt="保険を通してお客様のライフプランを提案します(SP)"
      class="top-message sp-only tablet-only"
    />
    <!-- <video
      src="../../assets/home.mov"
      autoplay
      muted
      playsinline
      loop
      class="top-video"
    ></video>
    <img
      src="../../assets/triage_top_logo0002.webp"
      alt="揖斐郡初の来店型保険ショップ 有限会社トリアージ SDGs 岐阜県推進ネットワーク会員に認定(PC)"
      class="top-logo pc-only"
    />
    <img
      src="../../assets/triage_top_logo0002sp.webp"
      alt="揖斐郡初の来店型保険ショップ 有限会社トリアージ SDGs 岐阜県推進ネットワーク会員に認定(SP)"
      class="top-logo sp-only"
    />
    <div class="top-cover">
      <h1 class="top-title">
        保険を通して<br />
        お客様のライフプランを<br />
        提案します
      </h1>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HomeTop",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.top {
  height: 100vh;
  position: relative;
  video {
    height: 100%;
    position: absolute;
    right: 0;
    /*動画よりも画面が縦に長くなるとき用*/
    @media (max-aspect-ratio: 16/9) {
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  img {
    position: absolute;
    bottom: 3%;
    right: 5%;
    width: 400px;
    z-index: 1;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      bottom: 10%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
      margin: 0 auto;
      bottom: 5vh;
    }
  }
  .sp-logo {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .top-cover {
    position: relative;
    background-color: var(--main);
    width: 410px;
    height: 100%;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 100%;
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 160px;
    }
  }
  .top-message {
    width: 600px;
    position: absolute;
    top: 40%;
    left: 6%;
    z-index: 1;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 20%;
      top: 110px;
    }
    @media screen and (max-width: 767px) {
      width: 30%;
      margin: 0;
      top: 40px;
    }
  }
}
</style>
