<template>
  <section id="line">
    <h4>
      保険やお金に関する
      <br class="sp-only" />
      保険情報をお探しなら
    </h4>
    <p class="line-text">
      LINE公式友だちになると、保険やお金に関する
      <br class="sp-only tablet-only" />
      最新情報や役立つ知識が手に入ります。
    </p>
    <p>
      ぜひ友だち追加ボタンをクリックして、
      <br class="sp-only tablet-only" />
      特典満載の情報をお楽しみください。
    </p>
    <div class="line-link">
      <a
        href="https://liff.line.me/1645278921-kWRPP32q/?accountId=745bdpgy"
        class="line-link-button"
      >
        <img
          src="../../assets/triage_top_LINE.webp"
          alt="LINE公式 友だち追加"
        />
      </a>
    </div>
    <p class="line-add">トリアージLINEに登録する</p>
  </section>
</template>

<script>
export default {
  name: "LineTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 0;
  h4 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 15px;
      line-height: 1.5rem;
      margin-bottom: 5px;
    }
  }
  .line-text {
    color: var(--line);
  }
  .line-link {
    display: flex;
    justify-content: center;
    .line-link-button {
      width: 15%;
      margin-top: 40px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        width: 40%;
        margin-top: 20px;
      }
      @media screen and (max-width: 767px) {
        width: 55%;
        margin-top: 20px;
      }
    }
  }
  .line-add {
    font-size: 16px;
    font-weight: 300;
  }
}
</style>
