<template>
  <div>
    <Header class="header" :path="path" />
    <div class="header-space">
      <!-- Headerの高さ埋める用 -->
    </div>
    <router-view class="body-top" />
    <img
      src="./assets/triage_top_modoru_B.webp"
      alt="TOPへ戻る"
      class="top-scroll"
      v-if="showScrollButton"
      v-scroll-to="'#app'"
    />
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: #3e3a39;
  line-height: 2em;
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

:root {
  --main: rgb(75, 138, 212);
  --sub: #f0f0f0;
  --line: rgb(6, 199, 85);
  --black: #000000;
  --white: #ffffff;
  --gray: #bcbcbc;
  --oneday: #D71318;
  --onedaylesr: #2a8881;
  --travel: #F47A45;
  --golf: #59B747;
  --cycle: #F6628D;
  --small-space: 100px;
  --small-space-sp: 50px;
  --space: 120px;
  --space-sp: 60px;
  --big-space: 200px;
  --big-space-sp: 100px;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.pc-only {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.pc-only-1150 {
  @media screen and (max-width: 1150px) {
    display: none;
  }
}
.pc-tablet-only {
  display: none;
  @media screen and (min-width: 768px) {
    display: initial !important;
  }
}
.tablet-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}
/*---------------------
 Heading
---------------------*/
h1,
h2 {
  font-size: 65px;
  font-weight: 500;
  color: var(--sub2);
  line-height: 4rem;
}
h3 {
  font-size: 36px;
  line-height: 1.5rem;
  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}
h4 {
  font-size: 28px;
}
p {
  font-size: 16px;
}
/*---------------------
 Section
---------------------*/
section {
  padding-top: 120px;
  padding-bottom: 120px;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .h-white {
    color: var(--white);
  }
  h1,
  h3 {
    font-size: 30px;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 80px;
    @media screen and (max-width: 767px) {
      line-height: 1.8rem;
      font-size: 26px;
      margin-bottom: 30px;
    }
    hr {
      border-color: var(--main);
      margin-top: 30px;
      margin-bottom: 0;
      width: 60px;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
  .wrap {
    width: 100vw; //画面幅に対して、基本100%
    max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
    margin: 0 auto;

    @media screen and (max-width: 1150px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .content {
    width: 70%;
    margin: 0 auto;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 80%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
}

/*---------------------
 詳細ページ
---------------------*/
.detail-page {
  h1 {
    line-height: 2.5rem;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      font-weight: bold;
      line-height: 2rem;
    }
  }
  .wrap {
    h2 {
      font-size: 30px;
      line-height: 35px;
      margin: 1.5rem 0 0.5rem 0;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
      }
    }
    p {
      line-height: 1.5rem;
      margin-bottom: 5px;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
        margin-bottom: 7px;
      }
    }
    .rule-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .fin {
      margin-top: 30px;
      @media screen and (max-width: 1100px) {
        margin-top: 20px;
      }
    }
  }
}
/*---------------------
 アナウンスメッセージ
---------------------*/
.announce {
  background-color: var(--main);
  padding: 20px;
  h4 {
    text-align: center;
    font-size: 30px;
    color: var(--white);
    @media screen and (max-width: 767px) {
      font-size: 25px;
      line-height: 2rem;
    }
  }
}
/*---------------------
 Headerスペースレンジャー
---------------------*/
.header-space {
  @media screen and (max-width: 767px) {
    height: 60px;
  }
}
/*---------------------
 共通スペースレンジャー
---------------------*/
.margin-bottom-40 {
  margin-bottom: 40px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.margin-bottom-80 {
  margin-bottom: 80px;
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}
.padding-y-100 {
  padding-top: var(--small-space);
  padding-bottom: var(--small-space);
  @media screen and (max-width: 767px) {
    padding-top: var(--small-space-sp);
    padding-bottom: var(--small-space-sp);
  }
}
/*---------------------
 TOPへスクロールボタン
---------------------*/
.top-scroll {
  width: 70px;
  position: fixed;
  bottom: 1px;
  right: 1px;
  z-index: 999;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 55px;
    bottom: 60px;
  }
}
</style>
