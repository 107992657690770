<template>
  <SolicitationPolicyComponent />
</template>

<script>
import SolicitationPolicyComponent from "../components/SolicitationPolicy/SolicitationPolicy.vue";

export default {
  name: "SolicitationPolicy",
  components: {
    SolicitationPolicyComponent,
  },
};
</script>

<style lang="scss"></style>
