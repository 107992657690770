<template>
  <section id="staff-introduction">
    <h3>
      スタッフ紹介
      <hr />
    </h3>
    <div class="staff-group wrap">
      <div class="staff-row margin-bottom-80">
        <div class="staff">
          <img src="../../assets/triage_top_0008.webp" alt="野原専務" />
          <p class="staff-name">専務 野原</p>
          <p>
            保険と言えば「難しい」「面倒」というイメージがあると思いますが、そのために私たちがいます。日々進化する保険について、日夜勉強しています。日常生活で起こるどんなことでもお気軽にご相談ください
          </p>
        </div>
        <div class="staff">
          <img src="../../assets/triage_top_0009.webp" alt="山田部長" />
          <p class="staff-name">部長 山田</p>
          <p>
            資産運用を強みにしており、高校でお金の授業やセミナーも行っています。最近話題のNISAやiDeCoのこともお任せください。どこの保険会社の、どの商品でも、当社で加入が無くても喜んでご説明させていただきます。お気軽にご相談ください。
          </p>
        </div>
      </div>
      <div class="content">
        <img
          src="../../assets/triage_top_0010.webp"
          alt="スタッフの集合写真"
          class="margin-bottom-40"
        />
        <div class="staff-list">
          <div class="staff-category">
            <p class="category">営業スタッフ</p>
            <div class="staff-overview">
              <div class="first-row"></div>
              <div class="staff-detail-row">
                <p class="staff-name">森 芳和（もり よしかず）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">小川 伸二（おがわ しんじ）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">野原 利秀（のはら としひで）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">山田 啓介（やまだ けいすけ）</p>
              </div>
            </div>
          </div>
          <div class="staff-category" id="footer-scroll-end">
            <p class="category">専務サポーター</p>
            <div class="staff-overview">
              <div class="first-row"></div>
              <div class="staff-detail-row">
                <p class="staff-name">松井 久代（まつい ひさよ）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">野原 みどり（のはら みどり）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">渡部 美和（わたなべ みわ）</p>
              </div>
              <div class="staff-detail-row">
                <p class="staff-name">中村 ルミ子（なかむら るみこ）</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StaffIntroductionTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub);
  .staff-group {
    .staff-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .staff {
        width: 48%;
        padding: 40px 0;
        background-color: var(--white);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        img {
          display: block;
          width: 65%;
          margin: 0 auto;
        }
        p {
          line-height: 1.7rem;
          width: 65%;
          margin: 10px auto 0 auto;
        }
        .staff-name {
          font-size: 20px;
          text-align: center;
        }
      }
    }
    .content {
      .staff-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .staff-category {
          width: 48%;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 40px;
          }
          .category {
            font-size: 20px;
            color: var(--main);
            margin-bottom: 20px;
            @media screen and (max-width: 1150px) {
              margin-bottom: 10px;
            }
          }

          .staff-overview {
            .first-row {
              width: 100%;
              border-bottom: 1px solid var(--black);
              @media screen and (max-width: 767px) {
                width: 100%;
              }
            }
            .staff-detail-row {
              display: flex;
              justify-content: start;
              width: 100%;
              margin-top: 3px;
              @media screen and (min-width: 768px) and (max-width: 1150px) {
                margin-top: 0;
              }
              @media screen and (max-width: 767px) {
                display: block;
              }
              .staff-name {
                color: var(--black);
                width: 100%;
                padding-left: 20px;
                border-bottom: 1px solid var(--black);
                padding-bottom: 3px;
                @media screen and (min-width: 768px) and (max-width: 1150px) {
                  padding-left: 5px;
                  font-size: 12px;
                }
                @media screen and (max-width: 767px) {
                  padding-left: 10px;
                  width: 100%;
                }
                span {
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
