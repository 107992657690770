/**
 * ページタイトル設定
 * @param pathTitle
 * @returns {string}
 */
export const setTitle = (pathTitle) => {
  const pageTitle = pathTitle;
  return (window.document.title = pageTitle);
};

/**
 * Description設定
 * @param pathMeta
 */
export const setDescription = (pathMeta) => {
  const defaultDescription = "This is initial description.";
  const description = pathMeta ? pathMeta : defaultDescription;
  document
    .querySelector("meta[name='description']")
    .setAttribute("content", description);
};

export const globalMixins = {
  methods: {
    setTitle,
    setDescription,
  },
};
