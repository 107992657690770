<template>
  <section
    id="insurance"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: ture,
      speed: 0.05,
    }"
  >
    <h3 class="h-white">
      取扱保険
      <hr />
    </h3>
    <div class="insurance-group wrap">
      <div class="insurance-row">
        <div class="insurance">
          <a href="https://www.ms-ins.com/" target="_blank">
            <img
              src="../../assets/triage_top_h0001.webp"
              alt="MS&AD 三井住友海上"
            />
          </a>
        </div>
        <div class="insurance">
          <a href="https://www.msa-life.co.jp/" target="_blank">
            <img
              src="../../assets/triage_top_h0002.webp"
              alt="MS&AD 三井住友海上あいおい生命"
            />
          </a>
        </div>
      </div>
      <div class="insurance-row">
        <div class="insurance">
          <a href="https://www.ms-primary.com/" target="_blank">
            <img
              src="../../assets/triage_top_h0003.webp"
              alt="MS&AD 三井住友海上プライマリー生命"
            />
          </a>
        </div>
        <div class="insurance">
          <a href="https://www.orixlife.co.jp/" target="_blank">
            <img
              src="../../assets/triage_top_h0004.webp"
              alt="ORIX オリックス生命"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsuranceTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  margin-top: 120px;
  background-image: url("../../assets/triage_top_back0001.webp");
  background-size: cover;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    margin-top: 90px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 60px;
    background-image: url("../../assets/triage_top_back0001sp.webp");
  }
  .insurance-group {
    .insurance-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 20px 0;
      @media screen and (max-width: 767px) {
        display: block;
        margin: 0;
      }
      .insurance {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin: 0 auto 5px 0;
        }
      }
    }
  }
}
</style>
