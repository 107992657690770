import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import VueHead from "vue-head";
import { VuePrlx } from "vue3-prlx";

createApp(App)
  .use(VueHead)
  .use(VueScrollTo)
  .use(store)
  .use(router)
  .use(VuePrlx)
  .mount("#app");
