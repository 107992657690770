<template>
  <section>
    <div class="wrap">
      <h3>インターネットでお申込みできる保険</h3>
      <div class="online-insurance-list">
        <div class="online-insurance box_red">
          <div class="netBack">
            <div class="oneday">＜クルマを借りたら忘れずに＞１ＤＡＹ保険</div>
            <img src="@/assets/oneday.png" alt="1DAY保険" class="netImg">
          </div>
          <div class="internetText2 first-row-height">
            借りたお車をしっかりサポート！
            <br>
            最大７日間まで。お申込みはスマートフォンからのみ可能です。
          </div>
          <div class="netLink-area">
            <button type="button" class="netLink bg_red" @click="insuranceLink('https://1day.ms-ins.com/servlet/sdr/SDR_UII_DRS0010?DRTSEQ=50000000000000324558&amp;DRTRNBN=20001&amp;dairiten_sub=&amp;dairiten_free=&amp;free_area1=&amp;free_area2=&amp;free_area3=1&amp;sennimoto=1&amp;random_key=')">お申込みはこちら</button>
          </div>
        </div>
        <div class="online-insurance box_green2 card-space">
          <div class="netBack">
            <div class="onedaylesr">＜海だ!山だ!ゴルフだ!＞１ＤＡＹレジャー保険</div>
            <img src="@/assets/onedaylesr.png" alt="1DAYレジャー保険" class="netImg">
          </div>
          <div class="internetText2 first-row-height">
            500円から加入できる1日分のレジャー保険。
            <br>
            レジャーに合ったタイプを選択できる「ひとりひとり手厚くプラン」や、幹事さんが参加者をまとめてお申込みできる「みんなまとめて安心プラン」をご用意！
            <br>
            最大７日間まで。お申込みはスマートフォンからのみ可能です。
          </div>
          <button type="button" class="netLink bg_green2" @click="insuranceLink('https://1day-leisure.ms-ins.com/servlet/sls/SLS_UII_LSS0010?DRTSEQ=50000000000000324558&DRTRNBN=B0001&dairiten_sub=&dairiten_free=&free_area1=&free_area2=&free_area3=&sennimoto=1&random_key=')">お申込みはこちら</button>
        </div>
        <div class="online-insurance box_orange">
          <div class="netBack">
            <div class="travel">＜海外旅行保険＞ネットde保険＠とらべる</div>
            <img src="@/assets/travel.png" alt="1DAYレジャー保険" class="netImg">
          </div>
          <div class="internetText2 first-row-height">
            保険料が魅力的！リピーターはさらに５％割引！
            <br>
            充実のサポート体制で安心！
            <br>
            <div class="internetText2Annotation">（※一定の要件を満たされた方が対象です。）</div>
          </div>
          <button type="button" class="netLink bg_orange" @click="insuranceLink('https://www.net.ms-ins.com/servlet/html/NAB_UII_UG0010.html?b_record=50000000000000324558&b_record_ren=60001&menber_no=0001&free_area1=&free_area2=&free_area3=1&random_key=')">お申込みはこちら</button>
        </div>
        <div class="online-insurance box_green">
          <div class="netBack">
            <div class="golf">＜ゴルファー保険＞ネットde保険＠ごるふ</div>
            <img src="@/assets/golf.png" alt="ネットde保険＠ごるふ" class="netImg">
          </div>
          <div class="internetText2 second-row-height">
            ゴルフプレー中のさまざまなトラブルをしっかりサポート！
          </div>
          <button type="button" class="netLink bg_green" @click="insuranceLink('https://net2.ms-ins.com/servlet/sgl/html/SGL_UII_GLS0010.html?DRTSEQ=50000000000000324558&DRTRNBN=50001&menber_no=0001&free_area1=&free_area2=&free_area3=1&random_key=')">お申込みはこちら</button>
        </div>
        <div class="online-insurance box_pink card-space">
          <div class="netBack">
            <div class="cycle">＜自転車向け保険＞ネットde保険＠さいくる</div>
            <img src="@/assets/cycle.png" alt="ネットde保険＠さいくる" class="netImg">
          </div>
          <div class="internetText2 second-row-height">
            自転車利用のリスクにそなえる！
            <br>
            事故の相手に対する賠償責任を最大3億円まで補償！
          </div>
          <button type="button" class="netLink bg_pink" @click="insuranceLink('https://net2.ms-ins.com/servlet/scy/html/SCY_UII_CYS0010.html?DRTSEQ=50000000000000324558&DRTRNBN=40001&menber_no=0001&free_area1=&free_area2=&free_area3=1&random_key=')">お申込みはこちら</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "OnlineInsurance",
  methods: {
    insuranceLink(link) {
      window.open(link, '_blank')
    },
  }
}
</script>
<style lang="scss" scoped>
section {
  background-color: var(--sub);
  .wrap {
    h3 {
      background-color: var(--black);
      font-size: 25px;
      line-height: 4rem;
      color: var(--white);
      @media screen and (max-width: 1100px) {
        font-size: 17px;
        line-height: 4rem;
      }
    }
    .online-insurance-list {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 1100px) {
        display: block;
      }
      .online-insurance {
        background-color: var(--white);
        width: 30%;
        font-size: 14px;
        line-height: 1.5rem;
        padding: 10px;
        margin-top: 0;
        margin-bottom: 30px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 1100px) {
          width: 100%;
        }
        .netBack {
          background-image: url("@/assets/internetBack2.png");
          background-repeat: repaet-x;
          background-position: bottom;
          background-size: 10px 100%;
          height: 120px;
          padding: 5px;
          box-sizing: border-box;
          .netImg {
            display: block;
            width: 200px;
            height: 80px;
            margin: auto;
          }
          .oneday {
            color: var(--oneday);
            font-weight: bold;
            padding-bottom: 5px;
            @media screen and (max-width: 1100px) {
              font-size: 13px;
              text-align: center;
            }
          }
          .onedaylesr {
            color: var(--onedaylesr);
            font-size: 13.5px;
            font-weight: bold;
            padding-bottom: 5px;
            @media screen and (max-width: 1100px) {
              font-size: 13px;
              text-align: center;
            }
          }
          .travel {
            color: var(--travel);
            font-weight: bold;
            padding-bottom: 5px;
            @media screen and (max-width: 1100px) {
              font-size: 13px;
              text-align: center;
            }
          }
          .golf {
            color: var(--golf);
            font-weight: bold;
            padding-bottom: 5px;
            @media screen and (max-width: 1100px) {
              font-size: 13px;
              text-align: center;
            }
          }
          .cycle {
            color: var(--cycle);
            font-weight: bold;
            padding-bottom: 5px;
            @media screen and (max-width: 1100px) {
              font-size: 13px;
              text-align: center;
            }
          }
        }
        .internetText2 {
          font-weight: 400;
          line-height: 1rem;
          padding: 5px 0 5px 0;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 13px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            text-align: center;
            width: 55%;
            margin-right: auto;
            margin-left: auto;
          }
          .internetText2Annotation {
            font-size: 11px;
            font-weight: normal;
          }
        }
        .first-row-height {
          @media screen and (min-width: 1100px) {
            height: 122px;
          }
        }
        .second-row-height {
          @media screen and (min-width: 1100px) {
            height: 58px;
          }
        }
        .netLink {
          cursor:pointer;
          display: block;
          border: 0 none;
          height: 45px;
          width: 200px;
          border-radius: 6px;
          margin: auto auto 0 auto;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
          background-image: url("@/assets/icon_white_arrow.png");
          background-repeat: no-repeat;
          background-position: 10px center;
          background-size: 20px;
          font-size: 16px;
          color: #fff !important;
          font-weight: 400;
        }
        .bg_red {
          background-color: var(--oneday);
        }
        .bg_green2 {
          background-color: var(--onedaylesr);
        }
        .bg_orange {
          background-color: var(--travel);
        }
        .bg_green {
          background-color: var(--golf);
        }
        .bg_pink {
          background-color: var(--cycle);
        }
      }
      .card-space {
        margin-right: 5%;
        margin-left: 5%;
        @media screen and (max-width: 1100px) {
          margin-right: 0;
          margin-left: 0;
        }
      }
      .box_red {
        border: 3px solid var(--oneday);
        border-radius: 10px;
      }
      .box_green2 {
        border: 3px solid var(--onedaylesr);
        border-radius: 10px;
      }
      .box_orange {
        border: 3px solid var(--travel);
        border-radius: 10px;
      }
      .box_green {
        border: 3px solid var(--golf);
        border-radius: 10px;
      }
      .box_pink {
        border: 3px solid var(--cycle);
        border-radius: 10px;
      }
    }
  }
}
</style>