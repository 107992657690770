<template>
  <PrivacyPolicyComponent />
</template>

<script>
import PrivacyPolicyComponent from "../components/PrivacyPolicy/PrivacyPolicy.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    PrivacyPolicyComponent,
  },
  mounted() {
    // コンポーネントがマウントされたときに実行される処理
    this.scrollToApp();
  },
  methods: {
    scrollToApp() {
      // #app にスクロール
      const appElement = document.getElementById("app");
      if (appElement) {
        appElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss"></style>
