<template>
  <section>
    <div class="backbround-gray padding-y-100">
      <div class="wrap facility-group">
        <div class="facility">
          <img
            src="../../assets/triage_top_0002.webp"
            alt="ミーティングルームのイメージ"
          />
          <p>ミーティングルーム</p>
        </div>
        <div class="facility">
          <img
            src="../../assets/triage_top_0003.webp"
            alt="キッズスペースのイメージ"
          />
          <p>キッズスペースあり</p>
        </div>
        <div class="facility">
          <img src="../../assets/triage_top_0004.webp" alt="駐車場のイメージ" />
          <p>駐車場も広々</p>
        </div>
      </div>
    </div>
    <div class="announce">
      <h4>いつでもお気軽に<br class="sp-only" />お立ち寄りください</h4>
    </div>
  </section>
</template>

<script>
export default {
  name: "FacilityTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 0;
  .backbround-gray {
    background-color: var(--sub);
    .facility-group {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .facility {
        width: 30%;
        @media screen and (max-width: 767px) {
          width: 80%;
          margin: 15px auto;
        }
        p {
          text-align: center;
          line-height: 1rem;
        }
      }
    }
  }
}
</style>
