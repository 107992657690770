<template>
  <section>
    <h3>
      当社までのアクセス
      <hr />
    </h3>
    <div class="wrap">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.94092279884!2d136.5676805757778!3d35.45625697266077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60024ba0db1ddecb%3A0xbbe0be8a1b574a0!2z5pyJ6ZmQ5Lya56S-44OI44Oq44Ki44O844K4!5e0!3m2!1sja!2sjp!4v1712290917416!5m2!1sja!2sjp"
        width="400"
        height="300"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        class="sp-only"
      ></iframe>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.94092279884!2d136.5676805757778!3d35.45625697266077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60024ba0db1ddecb%3A0xbbe0be8a1b574a0!2z5pyJ6ZmQ5Lya56S-44OI44Oq44Ki44O844K4!5e0!3m2!1sja!2sjp!4v1712290917416!5m2!1sja!2sjp"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        class="pc-tablet-only"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "AccessTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  aspect-ratio: 2 / 1;
}
</style>
