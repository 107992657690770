<template>
  <section
    id="company-overview"
    class="h-white"
    v-prlx.mobile.background="{
      direction: 'y',
      fromBottom: true,
      speed: 0.05,
    }"
  >
    <h3>
      会社概要
      <hr />
    </h3>
    <div class="company-group wrap">
      <div class="company-row">
        <div class="company">
          <img
            src="../../assets/triage_top_0005.webp"
            alt="有限会社トリアージ 建物イメージ"
          />
        </div>
        <div class="company">
          <div class="company-overview">
            <div class="first-row"></div>
            <div class="company-detail-row">
              <div class="company-detail-title">社名</div>
              <div class="company-detail">有限会社トリアージ</div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title">代表者</div>
              <div class="company-detail">野原克敏</div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title">従業員</div>
              <div class="company-detail">7名</div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title">所在地</div>
              <div class="company-detail">
                岐阜県揖斐郡池田町萩原
                <span>48-6</span>
              </div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title">電話</div>
              <div class="company-detail"><span>0585-45-4283</span></div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title"><span>FAX</span></div>
              <div class="company-detail"><span>0585-45-0623</span></div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title sp-only tablet-only">
                <span>営業時間</span>
              </div>
              <div class="company-detail-title pc-only">営業時間</div>
              <div class="company-detail"><span>9:00 〜 17:00</span></div>
            </div>
            <div class="company-detail-row">
              <div class="company-detail-title">定休日</div>
              <div class="company-detail">
                土曜<span>・</span>日曜<span>・</span>祝日
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CompanyOverviewTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("../../assets/triage_top_back0002.webp");
  background-size: cover;
  transition: background-position 0.1s ease-out 0s;
  background-position: 50% 21.06%;
  @media screen and (max-width: 1100px) {
    background-image: url("../../assets/triage_top_back0002sp.webp");
  }
  h3 {
    color: var(--white);
  }
  .company-group {
    .company-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 20px 0;
      @media screen and (max-width: 1150px) {
        display: block;
        margin-top: 0;
      }
      .company {
        width: 50%;
        @media screen and (max-width: 1150px) {
          width: 100%;
        }
        .company-overview {
          @media screen and (max-width: 1150px) {
            margin-top: 10px;
          }
          .first-row {
            width: 90%;
            margin: 0 auto;
            border-bottom: 1px solid var(--sub);
            @media screen and (max-width: 1150px) {
              width: 100%;
            }
          }
          .company-detail-row {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 10px;
            .company-detail-title {
              color: var(--white);
              letter-spacing: 1rem;
              width: 30%;
              padding-left: 10px;
              padding-bottom: 5px;
              border-bottom: 1px solid var(--sub);
              @media screen and (max-width: 1150px) {
                width: 35%;
                letter-spacing: 0.5rem;
              }
              span {
                letter-spacing: 0;
              }
            }
            .company-detail {
              color: var(--white);
              letter-spacing: 0.3rem;
              width: 60%;
              padding-left: 10px;
              padding-bottom: 5px;
              border-left: 1px solid var(--sub);
              border-bottom: 1px solid var(--sub);
              @media screen and (max-width: 1150px) {
                width: 65%;
                letter-spacing: 0.15rem;
              }
              span {
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
