<template>
  <section id="ceo-profile">
    <h3>
      代表者
      <hr />
    </h3>
    <div class="ceo-profile wrap">
      <img src="../../assets/triage_top_0006.webp" alt="野原克敏" />
      <div class="ceo-discription wrap">
        <div class="philosophy">
          <p class="ceo-dream margin-bottom-40">
            お客様第一をモットーに、<br />安心して加入できる企業でありたい
          </p>
          <img
            src="../../assets/triage_top_pa0001_B.webp"
            alt="これが私の想いです。"
            class="pc-only"
          />
          <img
            src="../../assets/triage_top_pa0001_Bsp.webp"
            alt="これが私の想いです。"
            class="sp-only tablet-only"
          />
        </div>
        <!-- プロフィール -->
        <div class="ceo-overview">
          <div class="first-row"></div>
          <div class="profile-row">
            <p class="profile-title">名前</p>
            <p class="profile">野原 克敏（のはら かつとし）</p>
          </div>
          <div class="profile-row">
            <p class="profile-title">生年月日</p>
            <p class="profile">1976 年 12月 3日</p>
          </div>
          <div class="profile-row">
            <p class="profile-title">出身地</p>
            <p class="profile">揖斐郡池田町</p>
          </div>
          <div class="profile-row">
            <p class="profile-title">趣味</p>
            <p class="profile">
              ゴルフ・ドライブ・
              <br class="sp-only tablet-only" />
              週末は中学生に野球指導に没頭中
            </p>
          </div>
          <div class="profile-row">
            <p class="profile-title">好きな言葉</p>
            <p class="profile">努力は必ず報われる</p>
          </div>
          <div class="careeer">
            <h4>経歴</h4>
            <p>平成13年8月 三井住友海上（旧住友海上）火災保険株式会社のIC社員（研修社員）として入社</p>
            <p>平成16年7月 3年間の研修期間を経て、野原保険サービスとして独立</p>
            <p>平成17年9月 有限会社トリアージとして法人化</p>
          </div>
        </div>
      </div>
    </div>
    <div class="ceo-message wrap">
      <img src="../../assets/triage_top_0007.webp" alt="野原克敏の仕事風景" />
      <p>
        代表の野原克敏です。２４才の時に建設業界から保険業界に転職しましたが、転職したての頃は当初思い描いていたような営業とは違い、数々の困難にぶつかり苦戦する毎日でした。<br />しかし、お客さまをはじめ多くの方々に支えられて、ここまで成長することができ、今はその恩返しと、もっとお客さまのお役に立ちたいとの想いから日々頑張っています。<br />いつでも気軽にご相談ください。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "CeoProfileTriage",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ceo-profile {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: initial;
  }

  img {
    width: 30%;
    height: 30%;
    @media screen and (max-width: 767px) {
      display: block;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ceo-discription {
    width: 65%;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      margin: 0;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      margin-top: 25px;
    }
    .philosophy {
      position: relative;
      .ceo-dream {
        font-size: 25px;
        color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 21px;
        }
      }
      img {
        width: 196px;
        height: 125.5px;
        position: absolute;
        right: 70px;
        bottom: -20px;
        @media screen and (min-width: 768px) and (max-width: 1150px) {
          right: -10px;
          top: -100px;
        }
        @media screen and (max-width: 767px) {
          width: 130.6px;
          height: 83.6px;
          right: -10px;
          top: -90px;
        }
      }
    }
    .ceo-overview {
      .first-row {
        width: 100%;
        border-bottom: 1px solid var(--black);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .profile-row {
        display: flex;
        justify-content: start;
        width: 100%;
        margin-top: 5px;
        .profile-title {
          color: var(--black);
          width: 20%;
          padding-left: 20px;
          padding-bottom: 5px;
          border-bottom: 1px solid var(--black);
          @media screen and (min-width: 768px) and (max-width: 1150px) {
            padding-left: 5px;
          }
          @media screen and (max-width: 767px) {
            width: 30%;
            padding-left: 5px;
            font-size: 14px;
            line-height: 1.5rem;
          }
        }
        .profile {
          color: var(--black);
          width: 80%;
          padding-left: 20px;
          padding-bottom: 7px;
          border-left: 1px solid var(--black);
          border-bottom: 1px solid var(--black);
          @media screen and (max-width: 767px) {
            width: 70%;
            padding-left: 5px;
            font-size: 14px;
            line-height: 1.5rem;
          }
        }
      }
      .careeer {
        margin-top: 30px;
        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
        h4 {
          font-size: 20px;
          color: var(--main);
          border-bottom: 1px solid var(--black);
        }
        p {
          @media screen and (max-width: 1150px) {
            line-height: 1.5rem;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.ceo-message {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 80px;
  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 40px;
  }
  img {
    width: 35%;
    aspect-ratio: 3 / 2;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 40%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  p {
    width: 60%;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      width: 55%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
